import React, { useRef, useState } from 'react';
import { BrainCog, Book, Sparkles } from 'lucide-react';

const LandingPage = () => {
  const formRef = useRef(null);
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState('');

  const scrollToForm = () => {
    formRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus('sending');

    try {
      const response = await fetch('https://formspree.io/f/xyzyjyjj', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          email,
          message,
          _replyto: email,
          _subject: 'New Mylore.ai Waitlist Signup',
          _to: 'hello@mylore.ai'
        }),
      });

      if (response.ok) {
        setStatus('success');
        setEmail('');
        setMessage('');
      } else {
        setStatus('error');
      }
    } catch (err) {
      setStatus('error');
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 to-gray-800">
      {/* Navigation */}
      <nav className="p-6">
        <div className="mx-auto max-w-6xl flex justify-between items-center">
          <h1 className="text-2xl font-bold text-white">mylore.ai</h1>
          <div className="space-x-4">
            <a href="#features" className="text-gray-300 hover:text-white">Features</a>
            <a href="#waitlist" className="text-gray-300 hover:text-white">Join Waitlist</a>
          </div>
        </div>
      </nav>

      {/* Hero Section */}
      <div className="py-20 px-6">
        <div className="mx-auto max-w-4xl text-center">
          <h2 className="text-5xl font-bold text-white mb-6">
            Your Personal AI Storyteller
          </h2>
          <p className="text-xl text-gray-300 mb-8">
            Create and explore unique story worlds powered by artificial intelligence
          </p>
          <button 
            onClick={scrollToForm}
            className="bg-blue-600 hover:bg-blue-700 text-white px-8 py-3 rounded-lg text-lg font-semibold transition-colors"
          >
            Join Waitlist
          </button>
        </div>
      </div>

      {/* Features */}
      <div id="features" className="py-20 px-6 bg-gray-800/50">
        <div className="mx-auto max-w-6xl">
          <div className="grid md:grid-cols-3 gap-12">
            <FeatureCard 
              icon={<BrainCog size={32} />}
              title="AI-Powered Creation"
              description="Generate unique stories and characters using advanced AI technology"
            />
            <FeatureCard 
              icon={<Book size={32} />}
              title="Story Worlds"
              description="Build and explore interconnected narrative universes"
            />
            <FeatureCard 
              icon={<Sparkles size={32} />}
              title="Interactive Experience"
              description="Engage with your stories through dynamic interactions"
            />
          </div>
        </div>
      </div>

      {/* Waitlist Form */}
      <div id="waitlist" ref={formRef} className="py-20 px-6">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-3xl font-bold text-white mb-4">Join the Waitlist</h2>
          <p className="text-gray-300 mb-8">Be among the first to experience the future of AI storytelling</p>
          
          <form onSubmit={handleSubmit} className="bg-gray-800 p-8 rounded-lg">
            <input 
              type="email" 
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
              required
              className="w-full px-4 py-2 rounded-lg bg-gray-700 text-white border border-gray-600 mb-4"
            />
            <textarea 
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Tell us what excites you about mylore.ai (optional)"
              rows={4}
              className="w-full px-4 py-2 rounded-lg bg-gray-700 text-white border border-gray-600 mb-4"
            />
            <button 
              type="submit"
              disabled={status === 'sending'}
              className="bg-blue-600 hover:bg-blue-700 text-white px-6 py-2 rounded-lg font-semibold transition-colors disabled:opacity-50"
            >
              {status === 'sending' ? 'Sending...' : 'Join Waitlist'}
            </button>
            
            {status === 'success' && (
              <p className="mt-4 text-green-400">Thanks for joining! We'll be in touch soon.</p>
            )}
            {status === 'error' && (
              <p className="mt-4 text-red-400">Something went wrong. Please try again.</p>
            )}
          </form>
        </div>
      </div>

      {/* Footer */}
      <footer className="py-8 px-6 border-t border-gray-700">
        <div className="mx-auto max-w-6xl text-center text-gray-400">
          <p>© 2024 mylore.ai - All rights reserved</p>
        </div>
      </footer>
    </div>
  );
};

const FeatureCard = ({ icon, title, description }) => {
  return (
    <div className="bg-gray-800 p-6 rounded-lg text-center">
      <div className="inline-block p-3 bg-blue-600 rounded-lg text-white mb-4">
        {icon}
      </div>
      <h3 className="text-xl font-semibold text-white mb-2">{title}</h3>
      <p className="text-gray-300">{description}</p>
    </div>
  );
};

export default LandingPage;